import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6b3b0dd3 = () => interopDefault(import('../pages/400.vue' /* webpackChunkName: "pages/400" */))
const _bfbd6dd8 = () => interopDefault(import('../pages/500.vue' /* webpackChunkName: "pages/500" */))
const _a2397e48 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _e1f92ab8 = () => interopDefault(import('../pages/change/index.vue' /* webpackChunkName: "pages/change/index" */))
const _64f8876a = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _50f763fe = () => interopDefault(import('../pages/logout/index.vue' /* webpackChunkName: "pages/logout/index" */))
const _fdf38d9c = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _0c221f97 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _47ca51b9 = () => interopDefault(import('../pages/remind/index.vue' /* webpackChunkName: "pages/remind/index" */))
const _83e16e72 = () => interopDefault(import('../pages/usersetting/index.vue' /* webpackChunkName: "pages/usersetting/index" */))
const _81b57fd0 = () => interopDefault(import('../pages/change/mail/index.vue' /* webpackChunkName: "pages/change/mail/index" */))
const _ef5daaf2 = () => interopDefault(import('../pages/register/complete/index.vue' /* webpackChunkName: "pages/register/complete/index" */))
const _691431d2 = () => interopDefault(import('../pages/register/input/index.vue' /* webpackChunkName: "pages/register/input/index" */))
const _e87ea9f6 = () => interopDefault(import('../pages/register/mail/index.vue' /* webpackChunkName: "pages/register/mail/index" */))
const _98fb82b6 = () => interopDefault(import('../pages/remind/complete/index.vue' /* webpackChunkName: "pages/remind/complete/index" */))
const _352703ba = () => interopDefault(import('../pages/remind/mail/index.vue' /* webpackChunkName: "pages/remind/mail/index" */))
const _716d0a02 = () => interopDefault(import('../pages/auth/line/link/index.vue' /* webpackChunkName: "pages/auth/line/link/index" */))
const _d3af9d12 = () => interopDefault(import('../pages/auth/line/login/index.vue' /* webpackChunkName: "pages/auth/line/login/index" */))
const _44beb4b2 = () => interopDefault(import('../pages/change/mail/link/index.vue' /* webpackChunkName: "pages/change/mail/link/index" */))
const _ab7ca234 = () => interopDefault(import('../pages/change/mail/verify/index.vue' /* webpackChunkName: "pages/change/mail/verify/index" */))
const _7ffd88b1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/400",
    component: _6b3b0dd3,
    name: "400"
  }, {
    path: "/500",
    component: _bfbd6dd8,
    name: "500"
  }, {
    path: "/auth",
    component: _a2397e48,
    name: "auth"
  }, {
    path: "/change",
    component: _e1f92ab8,
    name: "change"
  }, {
    path: "/login",
    component: _64f8876a,
    name: "login"
  }, {
    path: "/logout",
    component: _50f763fe,
    name: "logout"
  }, {
    path: "/maintenance",
    component: _fdf38d9c,
    name: "maintenance"
  }, {
    path: "/register",
    component: _0c221f97,
    name: "register"
  }, {
    path: "/remind",
    component: _47ca51b9,
    name: "remind"
  }, {
    path: "/usersetting",
    component: _83e16e72,
    name: "usersetting"
  }, {
    path: "/change/mail",
    component: _81b57fd0,
    name: "change-mail"
  }, {
    path: "/register/complete",
    component: _ef5daaf2,
    name: "register-complete"
  }, {
    path: "/register/input",
    component: _691431d2,
    name: "register-input"
  }, {
    path: "/register/mail",
    component: _e87ea9f6,
    name: "register-mail"
  }, {
    path: "/remind/complete",
    component: _98fb82b6,
    name: "remind-complete"
  }, {
    path: "/remind/mail",
    component: _352703ba,
    name: "remind-mail"
  }, {
    path: "/auth/line/link",
    component: _716d0a02,
    name: "auth-line-link"
  }, {
    path: "/auth/line/login",
    component: _d3af9d12,
    name: "auth-line-login"
  }, {
    path: "/change/mail/link",
    component: _44beb4b2,
    name: "change-mail-link"
  }, {
    path: "/change/mail/verify",
    component: _ab7ca234,
    name: "change-mail-verify"
  }, {
    path: "/",
    component: _7ffd88b1,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
